import { MutableRefObject, Ref, useMemo, useRef } from 'react';

type RefType<T> = Ref<T | null> | ((it: T | null) => void);

/**
 * Drop in replacement for the useRef hook which additionally allows combining an existing ref with the new ref that is
 * created by this hook. When combining two refs a new ref is created which updates the values of both refs. This hook
 * is intended to be used in cases where refs are passed through deeply nested compound component structures such as
 * the filter menu.
 */
export function useCombinedRef<T>(...controlledRefs: ReadonlyArray<RefType<T>>): MutableRefObject<T | null> {
    const thisRef = useRef<T | null>();
    return useMemo(
        () => ({
            set current(it: T | null) {
                thisRef.current = it;
                controlledRefs.forEach(otherRef => {
                    if (otherRef !== null && otherRef !== undefined) {
                        if (typeof otherRef === 'function') {
                            otherRef(it);
                        } else if (typeof otherRef === 'object') {
                            (otherRef as MutableRefObject<T | null>).current = it;
                        } else {
                            throw new Error(`unsupported ref type <${typeof otherRef}>`);
                        }
                    }
                });
            },
            get current(): T | null {
                return thisRef.current ?? null;
            }
        }),
        // If we don't spread this, the array will cause constant re-evaluations
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thisRef, ...controlledRefs]
    );
}
