import { RefCallback, useEffect, useState } from 'react';

import { useDeepMemo } from '../utils/useDeepMemo';

type MutationObserverResult<T> = {
    ref: RefCallback<T>;
};

export const useMutationObserver = <T extends Node>(
    callback: () => void,
    options?: MutationObserverInit
): MutationObserverResult<T> => {
    const [ref, setRef] = useState<T | null>(null);
    const memoedOptions = useDeepMemo(() => options, [options]);
    useEffect(() => {
        if (!ref) return;

        const observer = new MutationObserver(callback);
        observer.observe(ref, memoedOptions);
        return () => {
            observer?.disconnect();
        };
    }, [ref, callback, memoedOptions]);

    return { ref: setRef };
};
