import classNames from 'classnames';
import { FC } from 'react';

import { Icon } from './Icon';

type Props = {
    readonly direction: ScrollDirection;
    readonly onClick: () => void;
};

export enum ScrollDirection {
    none = 0,
    left = -1,
    right = 1
}

export const ScrollControl: FC<Props> = ({ direction, onClick }) => (
    <div className={direction === ScrollDirection.right ? 'page-right-wrapper' : 'page-left-wrapper'}>
        <button
            className={classNames(
                direction === ScrollDirection.right ? 'page-right' : 'page-left',
                'libcolor-text-hover'
            )}
            onClick={onClick}
            tabIndex={-1}
            aria-hidden={true}
        >
            <Icon icon="angle-bracket-outline-right" />
        </button>
    </div>
);
