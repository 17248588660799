import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../components/Icon';
import { useTranslatedProductGroup } from '../../hooks/utils/useTranslatedProductGroup';

/**
 * Props for <TopInfoLine>.
 */
export type TopInfoLineProps = {
    /**
     * The title of the category that contains this product group.
     */
    categoryTitle?: string;

    /**
     * The title describing the product group.
     */
    title: string | undefined;

    /**
     * The total counts of all found products within this product group.
     */
    totalCount?: number;

    /**
     * A link pointing to the page where all products of this product group can be seen.
     */
    seeAllUri?: string;

    /**
     * A boolean which control the appearance of the "See All" link
     */
    showSeeAllLink?: boolean;
};

export const TopInfoLine: FC<TopInfoLineProps> = ({
    categoryTitle,
    title,
    totalCount = 0,
    seeAllUri,
    showSeeAllLink = false
}) => {
    const { t } = useTranslation();
    const hasAvailableProducts = totalCount > 0;
    const categoryTitleTranslated = useTranslatedProductGroup(categoryTitle);

    return (
        <div className="group-header">
            {categoryTitle ? <div className="subtitle">{categoryTitleTranslated}</div> : null}
            {showSeeAllLink && seeAllUri && hasAvailableProducts ? (
                <SeeAllLink uri={seeAllUri} label={t('defaults.productGroup.see_all')} />
            ) : null}
            <div className="title">{seeAllUri ? <Link href={seeAllUri}>{title}</Link> : title}</div>
        </div>
    );
};

type SeeAllLinkProps = {
    uri: string;
    label?: string;
};

function SeeAllLink({ uri, label }: SeeAllLinkProps) {
    return (
        <Link href={uri} className="group-link libcolor-text">
            <span>
                {label} <Icon icon="caret-right" />
            </span>
        </Link>
    );
}
