import { Children, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TopInfoLine } from '../../page-components/featured/TopInfoLine';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { ScrollControl, ScrollDirection } from '../ScrollControl';
import { ScrollItemSize, SideScroll, useSideScroll } from '../Sidescroll';
import { Skippable } from '../Skippable';

type ProductTileGroupProps = {
    readonly categoryTitle?: string;
    readonly title: string | undefined;
    readonly totalCount?: number;
    readonly loanFormat?: LoanFormatType;
    readonly children?: ReactNode;
    readonly showTopInfoLine?: boolean;
    readonly seeAllUri?: string;
};

export const ProductTileGroup: FC<ProductTileGroupProps> = ({
    categoryTitle,
    title,
    totalCount = 0,
    children,
    seeAllUri,
    showTopInfoLine = true
}) => {
    const { t } = useTranslation();
    const showSeeAllLink = Children.count(children) < totalCount;

    const { prev, next, scrollLeftPossible, scrollRightPossible, ...sideScrollProps } = useSideScroll({
        step: 3,
        itemSize: ScrollItemSize.GildAuto
    });

    return (
        <div className="item-group product-group">
            {showTopInfoLine ? (
                <TopInfoLine
                    totalCount={totalCount}
                    categoryTitle={categoryTitle}
                    title={title}
                    seeAllUri={seeAllUri}
                    showSeeAllLink={showSeeAllLink}
                />
            ) : null}
            <Skippable title={t('defaults.productGroup.skip', { productGroupTitle: title })}>
                {Children.count(children) > 0 ? (
                    <div className="group-body">
                        <SideScroll {...sideScrollProps} className="item-list" itemClassName="list-item">
                            {children}
                        </SideScroll>
                        {scrollLeftPossible ? <ScrollControl direction={ScrollDirection.left} onClick={prev} /> : null}
                        {scrollRightPossible ? (
                            <ScrollControl direction={ScrollDirection.right} onClick={next} />
                        ) : null}
                    </div>
                ) : (
                    <div className="group-body">
                        <p>{t('defaults.productGroup.no_items')}</p>
                    </div>
                )}
            </Skippable>
        </div>
    );
};
