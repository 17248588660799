import { FC } from 'react';

import { ProductTileGroup } from './product-tile/ProductTileGroup';
import { StandardProductTileContent } from './product-tile/StandardProductTileContent';
import { ShortProduct } from '../domain/product';
import { useTranslatedProductGroup } from '../hooks/utils/useTranslatedProductGroup';
import { isPresent } from '../utils/objectChecks';

type ProductGroupProps = {
    readonly categoryTitle?: string;
    readonly title: string | undefined;
    readonly totalCount: number;
    readonly products: ReadonlyArray<ShortProduct>;
    readonly seeAllUri?: string;
};

export const ProductGroup: FC<ProductGroupProps> = ({ categoryTitle, title, totalCount, products, seeAllUri }) => {
    const titleTranslated = useTranslatedProductGroup(title);

    return isPresent(products) ? (
        <ProductTileGroup
            categoryTitle={categoryTitle}
            title={titleTranslated}
            totalCount={totalCount}
            seeAllUri={seeAllUri}
        >
            {products.map((product, idx) => (
                <StandardProductTileContent
                    key={
                        // Product ID itself isn't guaranteed to be unique. For example there may be duplicate
                        // products in the loan history. Using only the index doesn't work either, because we want
                        // the product tile to invalidate as soon as it contains a new product.
                        `${product.productId}-${idx}`
                    }
                    product={product}
                />
            ))}
        </ProductTileGroup>
    ) : null;
};
