import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
    Children,
    ComponentProps,
    isValidElement,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import { useScopeContext } from '../context/ScopeContext';
import { useMutationObserver } from '../hooks/events/useMutationObserver';
import { useCombinedRef } from '../hooks/memos/useCombinedRef';
import { MediaQuery, useMediaQuery } from '../utils/styles/breakpoints';

export enum ScrollItemSize {
    FixedWidth,
    Centered,

    /**
     * Gild uses Centered on small devices and FixedWidth on large ones (min-width: 1000px).
     */
    GildAuto
}

/**
 * Internal properties which must be passed from the `useSideScroll` hook to the `<SideScroll>` component.
 */
interface IUseSideScrollProps {
    /**
     * Ref to the outermost container element of the `<SideScroll>` component.
     *
     * NOTE: this is an internal property which should be passed from `useSideScroll` to the `<SideScroll>` component
     * directly.
     */
    _containerRef: MutableRefObject<HTMLUListElement | null>;

    /**
     * Callback which is triggered by the `<SideScroll>` component when the internal scroll state must be refreshed.
     *
     * NOTE: this is an internal property which should be passed from `useSideScroll` to the `<SideScroll>` component
     * directly.
     */
    _refresh: () => void;

    /**
     * The size type of the scroll items.
     *
     * NOTE: this is an internal property which should be passed from `useSideScroll` to the `<SideScroll>` component
     * directly.
     */
    _itemSize: ScrollItemSize;

    /**
     * Callback which can be used to trigger scrolling to the item with the given index.
     *
     * NOTE: this is an internal property which should be passed from `useSideScroll` to the `<SideScroll>` component
     * directly.
     */
    _to: (itemIndex: number, immediate?: boolean) => void;
}

/**
 * Result of a `useSideScroll` hook call.
 */
interface IUseSideScroll extends IUseSideScrollProps {
    /**
     * Callback which can be used to trigger scrolling to the next (right) element.
     */
    next: () => void;

    /**
     * Callback which can be used to trigger scrolling to the previous (left) element.
     */
    prev: () => void;

    /**
     * Callback which can be used to trigger scrolling to the item with the given index.
     */
    to: (itemIndex: number) => void;

    /**
     * Indicates whether the width of the scroll items exceeds the with of the scroll container.
     * Intended to be used to conditionally show/hide scroll buttons.
     */
    scrolling: boolean;

    /**
     * Indicates whether scrolling to the left is possible.
     * Intended to be used to conditionally show/hide the left scroll button.
     */
    scrollLeftPossible: boolean;

    /**
     * Indicates whether scrolling to the right is possible.
     * Intended to be used to conditionally show/hide the left scroll button.
     */
    scrollRightPossible: boolean;
}

/**
 * Configuration options for `useSideScroll`.
 */
interface IUseSideScrollOptions {
    /**
     * Indicates how the scroll items should be sized within the container.
     */
    itemSize?: ScrollItemSize;

    /**
     * The number of items that should be stepped when using the prev/next actions.
     *
     * Defaults to 1.
     */
    step?: number;

    /**
     * A threshold (in pixels) for the left/right offset of the scrolling area.
     * If the gap between the scrolling area and the scroll container becomes smaller than this threshold,
     * the `scrollLeftPossible` or `scrollRightPossible` will be set to `false`,
     * even though the scrolling is not fully finished.
     * This is useful, when the items do not have exact pixel widths.
     *
     * Defaults to `20` (pixels).
     */
    scrollThreshold?: number;

    /**
     * A threshold (in pixels) for determining the currently 'active' item.
     * The current item is the leftmost item which is still fully visible.
     * However, there are cases when an item has some styles which cause it to exceed the scrolling area,
     * although it is still 'fully visible' from a user perspective.
     * In these cases the item is still considered fully visible,
     * if the amount of which it exceeds the scrolling area does not exceed this threshold.
     *
     * Defaults to `20` (pixels).
     *
     * Side note:
     * The current item is used to determine which item to scroll to when the prev/next actions are triggered.
     * For example when the next action is triggered,
     * the `step` option has been set to 2 and the current item has index 3,
     * then the side scroll would scroll to the item with index 5.
     */
    currentItemThreshold?: number;
}

/**
 * A scroll strategy defines the flavor of how the internal state of the side scroll is updated. This includes the
 * calculation of scroll offsets when scrolling to specific items, the determination of the currently active item, as
 * well as the updating of the internal scroll state.
 */
interface IScrollStrategy {
    /**
     * Determines which item of the given container is currently 'active'. It is up to the strategy to decide which
     * criteria an item must meet in order to be considered active.
     */
    getCurrentItemIndex(container: HTMLElement, scrollThreshold: number): number;

    /**
     * Calculates the scroll offset required to scroll to the item with the given index.
     */
    getScrollOffsetForItem(container: HTMLElement, targetIndex: number): { targetOffset: number; targetIndex: number };

    /**
     * Determines the internal state of the side scroll depending on the given container.
     */
    getScrollState(container: HTMLElement, scrollThreshold: number): { prevPossible: boolean; nextPossible: boolean };
}

/**
 * The default scroll strategy. It left aligns the currently active item within the container.
 */
const DefaultScrollStrategy: IScrollStrategy = {
    getCurrentItemIndex(container, currentItemThreshold) {
        const containerOffsetWithThreshold = container.scrollLeft - currentItemThreshold;
        for (let childIndex = 0; childIndex < container.childElementCount; childIndex++) {
            const child = container.children.item(childIndex) as HTMLElement;
            const childOffset = child.offsetLeft - container.offsetLeft;
            if (childOffset >= containerOffsetWithThreshold) {
                return childIndex;
            }
        }
        return 0;
    },
    getScrollOffsetForItem(container, targetIndex) {
        const toItemIndex = Math.max(0, Math.min(container.childElementCount - 1, targetIndex));
        const targetItem = container.children.item(toItemIndex) as HTMLElement | null;

        if (targetItem) {
            const maxScroll = calcMaxScroll(container);
            const targetOffset = targetItem.offsetLeft - container.offsetLeft;
            const clampedTargetOffset = Math.max(0, Math.min(maxScroll, targetOffset));

            return {
                targetOffset: clampedTargetOffset,
                targetIndex: toItemIndex
            };
        }

        return {
            targetOffset: 0,
            targetIndex: toItemIndex
        };
    },
    getScrollState(container, scrollThreshold) {
        const offset = container.scrollLeft;
        const maxScroll = calcMaxScroll(container);
        const scrolling = container.scrollWidth > container.clientWidth;
        const prevPossible = scrolling && offset > scrollThreshold;
        const nextPossible = scrolling && offset < maxScroll - scrollThreshold;
        return {
            prevPossible,
            nextPossible
        };
    }
};

/**
 * A scroll strategy which centers the currently active item within the container.
 */
const CenteredScrollStrategy: IScrollStrategy = {
    getCurrentItemIndex(container) {
        if (container.scrollLeft === 0) {
            return 0;
        }
        const center = container.scrollLeft + container.clientWidth / 2;
        for (let childIndex = 0; childIndex < container.childElementCount; childIndex++) {
            const child = container.children.item(childIndex) as HTMLElement;
            const childLeft = child.offsetLeft - container.offsetLeft;
            const childRight = childLeft + child.clientWidth;
            if (childLeft <= center && childRight > center) {
                return childIndex;
            }
        }
        return 0;
    },
    getScrollOffsetForItem(container, targetIndex) {
        const toItemIndex = Math.max(0, Math.min(container.childElementCount - 1, targetIndex));
        const targetItem = container.children.item(toItemIndex) as HTMLElement | null;

        if (targetItem) {
            const maxScroll = calcMaxScroll(container);
            const targetOffset =
                targetItem.offsetLeft - container.offsetLeft - (container.clientWidth - targetItem.clientWidth) / 2;
            const clampedTargetOffset = Math.max(0, Math.min(maxScroll, targetOffset));

            return {
                targetOffset: clampedTargetOffset,
                targetIndex: toItemIndex
            };
        }

        return {
            targetOffset: 0,
            targetIndex: toItemIndex
        };
    },
    getScrollState: DefaultScrollStrategy.getScrollState
};

const getScrollStrategy = (itemSize: ScrollItemSize, isMin1000: boolean) => {
    switch (itemSize) {
        case ScrollItemSize.FixedWidth:
            return DefaultScrollStrategy;
        case ScrollItemSize.Centered:
            return CenteredScrollStrategy;
        case ScrollItemSize.GildAuto:
            return isMin1000 ? DefaultScrollStrategy : CenteredScrollStrategy;
    }
};

/**
 * Custom React Hook which contains all of the logic required to control a single `<SideScroll>` component.
 *
 * Usage:
 * ```tsx
 * const {next, prev, scrollLeftPossible, scrollRightPossible, ...sideScrollProps} = useSideScroll();
 *
 * <div>
 *   <SideScroll {...sideScrollProps}>
 *       <MyFirstItem/>
 *       <MySecondItem/>
 *       //...
 *   </SideScroll>
 *   {scrollLeftPossible && <MyCustomPrevButton onClick={prev}/>}
 *   {scrollRightPossible && <MyCustomNextButton onClick={next}/>}
 * </div>
 * ```
 */
export function useSideScroll({
    step = 1,
    scrollThreshold = 20,
    currentItemThreshold = 20,
    itemSize = ScrollItemSize.FixedWidth
}: IUseSideScrollOptions = {}): IUseSideScroll {
    const isMin1000 = useMediaQuery(MediaQuery.MinSpecial1000, false);
    const scrollStrategy = getScrollStrategy(itemSize, isMin1000);
    const containerRef = useRef<HTMLUListElement | null>(null);
    const currentItemIndex = useRef(0);
    const [scrollLeftPossible, setScrollLeftPossible] = useState(false);
    const [scrollRightPossible, setScrollRightPossible] = useState(false);
    const scrollLeftPossibleRef = useRef(scrollLeftPossible);
    const scrollRightPossibleRef = useRef(scrollRightPossible);

    const updateScrollState = useCallback(() => {
        const container = containerRef.current;
        if (container) {
            const { prevPossible, nextPossible } = scrollStrategy.getScrollState(container, scrollThreshold);

            const prevUpdated = scrollLeftPossibleRef.current !== prevPossible;
            const nextUpdated = scrollRightPossibleRef.current !== nextPossible;

            if (prevUpdated) {
                setScrollLeftPossible(prevPossible);
                scrollLeftPossibleRef.current = prevPossible;
            }

            if (nextUpdated) {
                setScrollRightPossible(nextPossible);
                scrollRightPossibleRef.current = nextPossible;
            }

            if (prevUpdated || nextUpdated) {
                return true;
            }
        }

        return false;
    }, [containerRef, setScrollLeftPossible, setScrollRightPossible, scrollThreshold, scrollStrategy]);

    const updateCurrentItemIndex = useCallback(() => {
        const container = containerRef.current;
        if (container) {
            const newItemIndex = scrollStrategy.getCurrentItemIndex(container, currentItemThreshold);
            if (newItemIndex !== currentItemIndex.current) {
                currentItemIndex.current = newItemIndex;
                return true;
            }
        }
        return false;
    }, [currentItemIndex, containerRef, currentItemThreshold, scrollStrategy]);

    const refresh = useCallback(() => {
        const stateUpdated = updateScrollState();
        const itemIndexUpdated = updateCurrentItemIndex();
        return stateUpdated || itemIndexUpdated;
    }, [updateScrollState, updateCurrentItemIndex]);

    const { ref: mutationRef } = useMutationObserver<HTMLUListElement>(refresh, { childList: true });

    const scrollTo = useCallback(
        (offset: number, immediate: boolean = false) => {
            const container = containerRef.current;
            if (container) {
                container.scrollTo({ left: offset, behavior: immediate ? 'auto' : 'smooth' });
            }
        },
        [containerRef]
    );

    const toItem = useCallback(
        (itemIndex: number, immediate: boolean = false) => {
            const container = containerRef.current;
            if (container) {
                const { targetIndex, targetOffset } = scrollStrategy.getScrollOffsetForItem(container, itemIndex);
                scrollTo(targetOffset, immediate);
                currentItemIndex.current = targetIndex;
            }
        },
        [containerRef, currentItemIndex, scrollTo, scrollStrategy]
    );

    const refreshDebounced = useMemo(() => debounce(refresh, 100), [refresh]);
    useEffect(() => {
        window.addEventListener('resize', refreshDebounced);
        return () => window.removeEventListener('resize', refreshDebounced);
    }, [refreshDebounced]);

    const prev = useCallback(
        () => toItem(currentItemIndex.current - Math.max(step, 1)),
        [currentItemIndex, toItem, step]
    );

    const next = useCallback(
        () => toItem(currentItemIndex.current + Math.max(step, 1)),
        [currentItemIndex, toItem, step]
    );

    return {
        _containerRef: useCombinedRef(containerRef, mutationRef),
        _refresh: refresh,
        _itemSize: itemSize,
        next,
        prev,
        to: toItem,
        _to: toItem,
        scrolling: scrollLeftPossible || scrollRightPossible,
        scrollLeftPossible,
        scrollRightPossible
    };
}

function calcMaxScroll(element: HTMLElement): number {
    return element.scrollWidth - element.clientWidth;
}

type ISideScrollProps = ComponentProps<'ul'> & {
    itemClassName?: string;
    firstItemClassName?: string;
    lastItemClassName?: string;
};

const ITEM_SIZE_CLASSES: { [S in ScrollItemSize]?: string } = {
    [ScrollItemSize.Centered]: 'side-scroll-item-centered'
};

/**
 * SideScroll container component. It is intended to be used in conjunction with the `useSideScroll` hook. For a
 * detailed usage example have a look at the JSDoc of `useSideScroll`.
 */
export function SideScroll({
    _containerRef: containerRef,
    _refresh: refresh,
    _to,
    className,
    _itemSize: itemSize,
    itemClassName,
    firstItemClassName,
    lastItemClassName,
    children,
    // --- public props which might be passed to this component
    //     they are listed here to avoid accidentally passing them down to the HTML element
    next,
    prev,
    to,
    scrolling,
    scrollLeftPossible,
    scrollRightPossible,
    // ---
    ...props
}: IUseSideScrollProps & ISideScrollProps & Partial<IUseSideScroll>) {
    const { loanFormat, ageCategory } = useScopeContext();

    // TODO: not 100% sure this is required, needs to be tested, but works for now, so no big issue
    // We have to refresh on loanFormat or ageCategory change as well, otherwise the SideScroller could potentially
    // show an arrow button even if the scroller is reset to the left hand side.
    useEffect(() => {
        refresh();
    }, [refresh, loanFormat, ageCategory]);

    const handleScroll = useMemo(() => debounce(refresh, 100), [refresh]);

    return (
        <ul
            // Force rerender when the user navigates to a different page, which makes sure all Sidescrolls are reset
            // to scrollLeft=0.
            // The easiest thing to notice a page change is to look for loanFormat and ageCategory changes. Other top
            // level transitions such as from Featured to Explore render a different page component and cause the
            // entire tree to rerender anyway.
            key={`${loanFormat}-${ageCategory}`}
            {...props}
            className={classNames('side-scroll', className)}
            onScroll={handleScroll}
            ref={containerRef}
        >
            {Children.map(children, (child, index) => (
                <li
                    key={isValidElement(child) && child.key !== null ? child.key : undefined}
                    className={classNames(
                        'side-scroll-item',
                        ITEM_SIZE_CLASSES[itemSize],
                        index === 0 && firstItemClassName
                            ? firstItemClassName
                            : index === Children.count(children) - 1 && lastItemClassName
                              ? lastItemClassName
                              : itemClassName
                    )}
                >
                    {child}
                </li>
            ))}
        </ul>
    );
}
